import { defineCustomElement } from "./fixes/apiCustomElement.ts";
import { createApp } from "vue";

import MainDevelop from "./source/components/MainDevelop.vue";
import CheckboxesWithInfo from "./source/components/CheckboxesWithInfo.ce.vue";
import LicensePlate from "./source/components/LicensePlate.ce.vue";
import SingleImage from "./source/components/SingleImage.ce.vue";
import SingleImageUpload from "./source/components/SingleImageUpload.ce.vue";
import MultiImageUpload from "./source/components/MultiImageUpload.ce.vue";
import MultiTextInput from "./source/components/MultiTextInput.ce.vue";
import NumberFormat from "./source/components/NumberFormat.ce.vue";
import ProgressBar from "./source/components/ProgressBar.ce.vue";
import TradeIn from "./source/components/TradeIn.ce.vue";
import TypeAheadSelect from "./source/components/TypeAheadSelect.ce.vue";
import AutocompleteWithInput from "./source/components/AutocompleteWithInput.ce.vue";

const noShadowRootOptions = { shadowRoot: false };

const LicensePlateComponent = defineCustomElement(
  LicensePlate,
  noShadowRootOptions,
);
const ProgressBarComponent = defineCustomElement(
  ProgressBar,
  noShadowRootOptions,
);
const TypeAheadSelectComponent = defineCustomElement(
  TypeAheadSelect,
  noShadowRootOptions,
);
const AutocompleteWithInputComponent = defineCustomElement(
  AutocompleteWithInput,
  noShadowRootOptions,
);
const MultiTextInputComponent = defineCustomElement(
  MultiTextInput,
  noShadowRootOptions,
);
const CheckboxesWithInfoComponent = defineCustomElement(
  CheckboxesWithInfo,
  noShadowRootOptions,
);
const SingleImageComponent = defineCustomElement(
  SingleImage,
  noShadowRootOptions,
);
const SingleImageUploadComponent = defineCustomElement(
  SingleImageUpload,
  noShadowRootOptions,
);
const MultiImageUploadComponent = defineCustomElement(
  MultiImageUpload,
  noShadowRootOptions,
);
const NumberFormatComponent = defineCustomElement(
  NumberFormat,
  noShadowRootOptions,
);

customElements.define("tradein-license-plate", LicensePlateComponent);
customElements.define("tradein-progress-bar", ProgressBarComponent);
customElements.define("tradein-type-ahead-select", TypeAheadSelectComponent);
customElements.define("tradein-autocomplete-with-input", AutocompleteWithInputComponent);
customElements.define("tradein-multi-text-input", MultiTextInputComponent);
customElements.define(
  "tradein-checkboxes-with-info",
  CheckboxesWithInfoComponent,
);
customElements.define(
  "tradein-single-image",
  SingleImageComponent,
);
customElements.define(
  "tradein-single-image-upload",
  SingleImageUploadComponent,
);
customElements.define("tradein-multi-image-upload", MultiImageUploadComponent);
customElements.define("tradein-number-format", NumberFormatComponent);

if (!process.env.APP_NO_WEBCOMPONENTS) {
  const TradeInComponent = defineCustomElement(TradeIn);
  customElements.define("trade-in", TradeInComponent);
}

const debugContainerId = "--debug--trade-in-app";
if (document.querySelector(`#${debugContainerId}`)) {
  createApp(MainDevelop).mount(`#${debugContainerId}`);
}
