<template>
  <div>
    <VueNumber v-if="!disabled" v-model="internalValue" :precision="precision" :decimal="decimal" :separator="separator"
      prefix="" :required="required" class="w-sm"></VueNumber>
    <div v-if="disabled">{{ formattedValue }}</div>
  </div>
</template>

<script>
import { component as VueNumber, NumberFormat } from '@coders-tm/vue-number-format'
import { utilities } from "../utils.js";

export default {
  name: "NumberFormat",
  components: { VueNumber },
  props: {
    value: {
      type: String,
      default: null,
    },
    precision: {
      type: Number,
      default: null,
    },
    decimal: {
      type: String,
      default: ",",
    },
    separator: {
      type: String,
      default: ".",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: "false",
    },
    required: {
      type: String,
      default: "false",
    },
    inputId: {
      type: String,
      required: true,
    },
  },
  emits: ["change"],

  data: () => ({
    internalValue: "",
    formatter: null,
    isInitializing: true
  }),

  computed: {
    formattedValue() {
      if (this.formatter) {
        return this.formatter.format(this.internalValue);
      }
      return this.internalValue;
    }
  },

  watch: {
    internalValue() {
      if (!this.isInitializing) {
        utilities.emitValueChanged(this.$el, this.inputId, this.internalValue);
      }
    },
    value() {
      this.internalValue = this.value;
    },
  },

  mounted() {
    if (this.value) {
      this.internalValue = this.value;
    }
    this.formatter = new NumberFormat({
      options: {
        precision: this.precision,
        decimal: this.decimal,
        separator: this.separator,
        masked: false,        
      }
    })

    this.$nextTick(() => {
      this.isInitializing = false;
    });
  },

  methods: {},

};
</script>
