<template>
  <div class="single-image">
    <div v-if="image" class="image-wrapper">
      <img v-load-image :alt="image.name" :data-src="image.data" />
    </div>
  </div>
</template>

<script>
import { UploadedImage } from "../models/uploaded-image.js";
import vLoadImage from "../directives/v-load-image.js";


export default {
  name: "SingleImage",
  directives: {
    loadImage: vLoadImage,
  },
  props: {
    value: {
      type: String, // json
      default: null,
    },
  },
  data: () => ({
    image: null,
  }),

  mounted() {
    let parsedValue = {};
    if (this.value) {
      parsedValue = JSON.parse(this.value) || {};

      const new_images = {};
      for (let [img_id, img_val] of Object.entries(parsedValue)) {
        if (img_val["url"]) {
          const img = new UploadedImage(img_id, img_val["url"]);
          img.url = img_val["url"];
          new_images[img_id] = img;
        }
      }
      let images_array = Object.values(new_images)
      if (images_array.length > 0) {
        this.image = images_array[0];
      }
    }
  }

}

</script>

<style lang="scss" scoped>
@import "../scss/components/_singleimage.scss";
</style>
